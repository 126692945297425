import React, { useEffect } from "react";
import $ from "jquery";

import Layout from "../../components/layout";
import SmallBanner from "../../components/smallBanner";

const Knowledge = () => {
  const lang = "de";

  useEffect(() => {
    $(".knowledge-wrapper--header").click(function () {
      if (!$(this).parent().hasClass("open")) {
        $(".knowledge-wrapper.open .knowledge-wrapper--header")
          .next()
          .slideUp();
        $(".knowledge-wrapper.open").removeClass("open");
      }
      $(this).next().slideToggle();
      $(this).parent().toggleClass("open");
    });
  });

  return (
    <Layout
      seoTitle="Wissensdatenbank"
      lang={lang}
      translationPL="/baza-wiedzy/"
      translationEN="/en/konwledge-base/"
    >
      <SmallBanner title="Baza wiedzy" lang={lang} />

      <section className="knowledge-main">
        <div className="container">
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
            Was ist der Unterschied zwischen einer EPAL Palette und einer nicht standardisierten Palette?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                Die EPAL 1 Europalette ist die beliebteste, 800×1200mm große Palette in Europa. Die Deckbretter (in Längsrichtung angeordnet) und die drei Kufen, die längs der Palette angeordnet sind, bestehen aus Massivholz, während die Klötze aus Massivholz oder Holzspänen bestehen können. Bei Epal Paletten handelt es sich um zertifizierte, entsprechend gekennzeichnete Produkte. Sie werden nach besonderen Vorschriften hergestellt und repariert. Der Kauf einer standardisierten Palette garantiert Qualität, Wiederholbarkeit und lange Lebensdauer der Palette.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
            Wie ist die Kennzeichnung auf einem EPAL-Palettenklotz zu interpretieren?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                Jede EPAL Europalette muss auf dem zentralen Klotz die Lizenznummer des Herstellers sowie das Jahr und den Monat der Herstellung aufweisen.
                  <br />
                  Lizenznummer - dreistellige Bezeichnung. <br />
                  Herstellungsjahr - einstellige Bezeichnung. <br />
                  Herstellungsmonat - zweistellige Bezeichnung. <br />
                  Jede neue EPAL Europalette, die im Rahmen des garantierten EPAL-Qualitätssystems hergestellt wird, muss der phytosanitären Behandlung nach ISPM15 unterzogen werden. Die phytosanitäre Behandlung wird durch eine Markierung auf dem zentralen Klotz bestätigt.. <br />
                  <br />
                  <img
                    src={require("../../assets/img/faq-ask-2.png")}
                    alt="oznaczenie palet"
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
            Was ist die HT-Kennzeichnung auf der Palette?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                 Bei der Wärmebehandlung gemäß ISPM 15 (International Standards for Phytosanitary Measures No 15) wird das Holz dermaßen erhitzt, dass im Holzkern, d.h. auch in den tiefsten Gebieten, eine Temperatur von mindestens 56°C erreicht wird. Diese Temperatur muss mindestens 30 Minuten lang aufrechterhalten werden. Ist dies der Fall, wird davon ausgegangen, dass eine thermische Sterilisation des Holzes durchgeführt wurde, d.h. die überwiegende Mehrheit der bekannten Organismen ist irreversibel unschädlich gemacht wurde. Holzverpackungen, z.B. eine Palette, die einer solchen Behandlung unterzogen wurde, können mit den Buchstaben HT aus dem englischen Heat Treatment gekennzeichnet werden.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
            Was ist der Unterschied zwischen der Art des Beladen "überlappend" und "übereinander"?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                  <strong>Überlappend (one in one)::</strong>
                  <br />
                  Die Paletten werden in Nestblöcken platziert, damit können die Paletten in einandergestapelt (eingesetzt werden). Sie sind so angeordnet, dass jede zweite Palette "auf den Kopf gestellt" wird und ihre Klötze an den Klötzen der nächsten Palette vorbeigeführt werden, so dass der verfügbare Lade- und Lagerraum maximal genutzt werden kann. Diese Art der Stapelung kann die automatische Entnahme von Paletten aus dem Stapel auf einer ungeeigneten Produktionslinie verhindern. <br />
                  <br />
                  <strong>„Überlappend (one in one):</strong>
                  <br />
                  Die Paletten werden in Stapeln übereinander gestapelt. Jede Palette steht auf den Deckbrettern der anderen Palette.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
            Welche Vorteile hat das Beladen übereinander?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                Paletten, die im 1/1-System (one on one) gestapelt werden, sind für Kunden bestimmt, die über automatisierte Linien für die direkte Palettenbeladung an der Produktionslinie verfügen.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
            Wie hoch ist die Feuchtigkeit einer Standardpalette?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                Der Feuchtigkeitsgehalt von neuen Europaletten darf keine 22% überschreiten. Auf Anfrage hergestellte Sonderpaletten können jedoch jeden beliebigen Feuchtigkeitsgehalt aufweisen.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
            Was ist der Unterschied zwischen Bläue und Schimmel?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                Holzschimmel ist ein Ergebnis des Pilzwachstums, das durch unsachgemäße Lagerung von Holz entsteht. Das Auftreten von Schimmelpilzen wird durch hohe Luftfeuchtigkeit, mangelnde Luftzirkulation und mäßig hohe Temperaturen begünstigt. Die Parameter von schimmelbefallenem Holz verschlechtern sich, so dass die Palette möglicherweise nicht den geforderten Standards entspricht. Aus diesem Grund ist es wichtig, dass der Palettenhersteller über eine geeignete Infrastruktur verfügt, um das Risiko von Schimmelpilzbefall zu minimieren.{" "}
                  <br />
                  <br />
                  Sehr oft wird Schimmelpilzbefall mit Bläue an Klötzen verwechselt, die gemäß dem Institut für Holztechnologie in Poznań nur eine ästhetische Veränderung der Holzfarbe darstellt, die Parameter des Materials jedoch nicht verschlechtert. Eine Palette mit Bläue ist ein vollwertiges Produkt.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
            Disqualifiziert die Bläue eine Palette?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                Nach der Stellungnahme des Instituts für Holztechnologie in Poznań hat die Bläue keinen Einfluss auf die Parameter des Holzes und damit auf die Endprodukte. Paletten mit sichtbarer Bläue an Klötzen können bedenkenlos als Warenträger verwendet werden.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
            Ist es möglich, eine für meine Bedürfnisse geeignete Palette zu konstruieren?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                Ja. Unsere Spezialisten stellen Ihnen gerne ein Projekt vor und produzieren Paletten, die Ihren individuellen Anforderungen entsprechen. Es ist auch möglich, eine Palette nach Ihrer Zeichnung herzustellen.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
            Wie schnell können Sie die Paletten liefern?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                Für EPAL, CP und Standard-Industriepaletten beträgt die Lieferzeit in Polen 48 Stunden. Für Lieferungen ins Ausland beträgt die Wartezeit 2-4 Werktage. Für Sonderpaletten beträgt die erste Lieferzeit 5-12 Werktage, je nach Materialverfügbarkeit. PalettenWerk bietet Ihnen die Möglichkeit, ein Pufferlager und Lieferungen auf Abruf einzurichten. Die Wartezeit für Paletten beträgt im Inland 48 Stunden und im Ausland 2-4 Werktage.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
            Können Sie Ihre Paletten mit nicht standardisierten Zeichen (z.B. meinem Logo) kennzeichnen?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                Ja. Es ist möglich, die Paletten nach dem vom Kunden übermittelten Muster zu kennzeichnen. Es kann ein Firmenlogo oder ein anderes Symbol zur Unterscheidung der Palette sein.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
            Wie können Sie die Palette markieren?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                Zur Kennzeichnung der Symbole verwenden wir die Heißprägetechnik (hot stamping) und den Druck mit hochauflösenden Spezialdruckern. Zusätzlich ist es möglich, die Palette so zu markieren, dass ihre Kontur oder die gesamte Oberfläche in unseren modernen Lackieranlagen in jeder beliebigen Farbe lackiert wird.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
            Bieten Sie Palettenverfolgungssysteme an?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                Ja, PalettenWerk, als einer der technologisch fortschrittlichsten Palettenhersteller, bietet die Möglichkeit, QR-Codes auf Paletten zu drucken und diese mit RFID-Chips auszustatten. Beide Lösungen können Sie an Ihr eigenes Tracking-System anpassen.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
            Was ist die Paletten-Qualitätskontrolle?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                Unser Werk ist nach ISO 9001:2015 zertifiziert und erfüllt strenge Qualitätsanforderungen. Diese Anforderungen werden in jeder Phase der Produktion, die viele Prozesse und Aktivitäten umfasst, eingehalten.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
            Welche Zertifikate hat Ihr Unternehmen?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                Das Unternehmen PalettenWerk verfügt über alle notwendigen Zertifikate und Zulassungen für die Palettenherstellung: <br />
                  <ul>
                    <li>EPAL Lizenz</li>
                    <li>ISO 9001:2015 Zertifikat</li>
                    <li>IPPC Zertifikat</li>
                    <li>PEFC Zertifikat</li>
                  </ul>
                  Diese Zertifikate und Lizenzen werden regelmäßig auf der Grundlage von Prüfungen durch die Vertreter der ausstellenden Organisationen erneuert.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
            In welche Gebiete liefern Sie Paletten?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                PalettenWerk, als einer der wichtigsten Hersteller von Paletten in Polen und Europa, exportiert fast 70% der Produktion, d.h. fast 500 Tausend Paletten pro Monat. Unsere Kunden befinden sich in 17 europäischen Ländern. Zu den größten Auslandsmärkten gehören, neben Polen, Italien, Deutschland, Schweiz, Frankreich und Österreich. Andere Absatzmärkte: Tschechen, Ukraine, Litauen, Slowakei, Rumänien, Dänemark, Ungarn, Belgien, Slowenien, Serbien, Holland, Spanien, Großbritannien. Wir schrecken vor Herausforderungen nicht zurück und erweitern das Portfolio unserer Kunden ständig um neue Bestimmungsorte.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
              Wie funktioniert der Palettenverkaufsprozess?

              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                Das Bedürfnis des Kunden ist der Hauptfaktor, der den gesamten Prozess in Gang setzt. PalettenWerk ist die einzig richtige Antwort, wenn sich die Bedürfnisse auf das Wort Palette konzentrieren. <br />
                Vom ersten Kontakt an bemühen sich unsere Berater, die tatsächlichen Bedürfnisse individuell zu ermitteln. Denn so wie jeder die Größe seiner Schuhe auf sich abstimmen muss, garantiert die Wahl der richtigen Palette Komfort und deutliche Einsparungen im Geschäft. Nachdem der Kunde seine Anforderungen formuliert hat, erstellen die Verkaufsberater in Zusammenarbeit mit den Konstrukteuren und der Logistikabteilung ein Angebot. Das Angebot entspricht den Erwartungen des Kunden und bietet das beste Preis-Leistungs-Verhältnis. Der Kunde nimmt aktiv an dem gesamten Prozess teil und beeinflusst das Endergebnis. Nach Annahme des Angebots werden auf Wunsch des Kunden Muster hergestellt und an ihn geliefert. In der letzten Phase beginnt die regelmäßige Zusammenarbeit.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
            Verwenden Sie zertifizierte Komponenten?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                Bei Zertifikaten wie PEFC ist PalettenWerk verpflichtet, auf bewährte Lieferanten zurückzugreifen, die nur zertifizierte Produkte anbieten. Alle Prozesse müssen die Anforderungen zahlreicher Standards erfüllen, die von globalen Organisationen festgelegt und durchgesetzt werden. Darüber hinaus organisiert PalettenWerk selbst Audits bei seinen Lieferanten, um die Qualität des gelieferten Rohmaterials zu überprüfen.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
            Wie kann ich bei Ihnen Paletten bestellen?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                Bestellungen geben Sie bitte per E-Mail bei unseren Verkaufsberatern oder unter {" "} 
                  <a href="mailto:palety@palettenwerk.pl">
                  palety@palettenwerk.pl auf
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
            Was hoch ist die Mindestbestellmenge?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                Die Mindestbestellmenge hängt von der Palettenausführung ab, Sie können diese bestimmen, indem Sie uns eine Anfrage an {" "} 
                  <a href="mailto:palety@palettenwerk.pl">
                  palety@palettenwerk.pl senden
                  </a>
                       .Bitte beachten Sie jedoch, dass die Mindestmenge mit einer dreistelligen Zahl angegeben wird.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
            Was sind Ihre Öffnungszeiten?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                Die verschiedenen Abteilungen unseres Unternehmens haben unterschiedliche Arbeitszeiten. Der Kundendienst ist von 8:00 bis 16:00 Uhr und Warenbeladung und -empfang von 06:00 bis 22:00 Uhr, montags bis freitags geöffnet.
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
            Wie erstellen Sie Ihr Angebot? Welche Faktoren beeinflussen den Preis von Paletten?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                Der Preis einer Palette hängt von folgenden Faktoren ab:
                  <ul>
                    <li>Marktpreis von Holz</li>
                    <li>Palettenstruktur</li>
                    <li>einmalige Menge</li>
                    <li>jährliche Gesamtmenge der bestellten Paletten</li>Um ein Angebot zu erhalten, senden Sie bitte Ihre Anfrage an den ausgewählten Verkaufsberater oder an {" "}
                    <a href="mailto:palety@palettenwerk.pl">
                    palety@palettenwerk.pl.
                    </a>
                    .
                  </ul>
                </p>
              </div>
            </div>
          </div>
          <div className="knowledge-wrapper">
            <h3 className="knowledge-wrapper--header">
            Können Sie einen Mindestbestand für mich halten?
              <span></span>
            </h3>
            <div
              className="knowledge-wrapper--desc"
              style={{ display: "none" }}
            >
              <div className="knowledge-wrapper--inner">
                <p>
                Es ist möglich, einen Mindestbestand zu halten, Einzelheiten können mit unserem Verkaufsberater vereinbart werden.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Knowledge;
